import React, {useState} from "react";
import { observer } from "mobx-react";
import {Alert, Button, Descriptions, Dropdown, Menu, Row, Space, Tooltip, Typography} from "antd";
import { Link } from "react-router-dom";
import "./credit.invoice.data.header.scss";
import { DocumentsStatus } from "../../../../common/documents/status/documents.status";
import Date from "../../../../date/date";
import {CreditInvoice} from "../../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {Value} from "../../../../common/value/value";
import {DocumentEditTransactionModal} from "../../../common/transaction/document.edit-transaction.modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {creditInvoicesService} from "../../../../../services/documents/invoices/credit.invoices.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {isAllowed} from "../../../../../utils/helpers";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {SourcesListModal} from "../../../common/sources/modal/sources-list/sources-list.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
import {senderService} from "../../../../../services/documents/common/sender.service";

export interface CreditInvoiceDataHeaderProps {
    document: CreditInvoice;
    reload: () => Promise<CreditInvoice>;
}

export const CreditInvoiceDataHeader: React.FC<CreditInvoiceDataHeaderProps> = observer(({document, reload}) => {
    const t = useTranslate();
    const navigate = useNavigate();

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const [transactionEditModalShown, setTransactionEditModalShown] = useState(false);
    const [sourcesListShown, setSourcesListShown] = useState(false);

    const close = async () => {
        await creditInvoicesService.close(document.id);
        reload();
        notifications.successfully();
    }

    const reopen = async () => {
        await creditInvoicesService.reopen(document.id);
        reload();
        notifications.successfully();
    }

    const uploadToSecondary = async () => {
        await creditInvoicesService.uploadToSecondary(document.id);
        notifications.successfully();
        reload();
    }

    const downloadFromSecondary = async () => {
        await creditInvoicesService.downloadFromSecondary(document.id);
        reload();
        notifications.successfully();
    }

    const cancel = async () => {
        await creditInvoicesService.cancel(document.id);
        reload();
        notifications.successfully();
    }

    const sendDocumentToClient = async () => {
        await senderService.send(document.id);
        notifications.successfully()
    }

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("INVOICES.CREDIT.TITLE") + " " + document.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/credit-invoices");
                }}
                extra={<DocumentsStatus large status={document.status} />}
            >
                <Space direction="vertical" size={[15, 15]}>
                    <Row justify={"space-between"}>
                        <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <Dropdown
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {document.orders && document.orders.length
                                            ? document.orders.map((order) => (
                                                  <Menu.Item key={order}>
                                                      <Link
                                                          style={{ textAlign: "left" }}
                                                          className={"ant-btn ant-btn-link"}
                                                          to={"/orders/" + order}
                                                      >
                                                          {order === document.masterOrder.id
                                                              ? order + " (master)"
                                                              : order}
                                                      </Link>
                                                  </Menu.Item>
                                              ))
                                            : null}
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ORDERS.LIST")}</Button>
                            </Dropdown>
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key={0}>
                                            <a
                                                rel="noopener noreferrer"
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + document.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={0}>
                                            <a
                                                rel="noopener noreferrer"
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/api/v3/documents/credit-invoices/" + document.id + "/xls"}
                                            >
                                                {t("DOCUMENTS.FILES.DOWNLOAD_XLS")}
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                            </Dropdown>

                        </Space>

                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    {document.status === "closed" ? (
                                        <>
                                            <Menu.Item
                                                key={1}
                                                onClick={reopen}
                                            >
                                                <Button
                                                    type="link"
                                                    style={{color: "inherit"}}
                                                >
                                                    {t("INVOICES.REOPEN.ACTION")}
                                                </Button>
                                            </Menu.Item>
                                        </>
                                    ) : null}
                                    {document.status === "pre_closed" ? (
                                        <>
                                            <Menu.Item
                                                key={2}
                                                onClick={downloadFromSecondary}
                                            >
                                                <Button
                                                    type="link"
                                                    style={{color: "inherit"}}
                                                >
                                                    {t("INVOICES.DOWNLOAD_FROM_SECONDARY.ACTION")}
                                                </Button>
                                            </Menu.Item>
                                            <Menu.Item
                                                key={3}
                                                onClick={close}
                                            >
                                                <Button
                                                    type="link"
                                                    style={{color: "inherit"}}>
                                                    {t("INVOICES.CLOSE.ACTION")}
                                                </Button>
                                            </Menu.Item>
                                        </>
                                    ) : null}

                                    {document.status === "opened" ? (
                                        <Menu.Item
                                            key={1}
                                            onClick={uploadToSecondary}
                                        >
                                            <Button
                                                type="link"
                                                style={{color: "inherit"}}
                                            >
                                                {t("INVOICES.UPLOAD_TO_SECONDARY.ACTION")}
                                            </Button>
                                        </Menu.Item>
                                    ) : null}

                                    {document.status !== "cancelled" ? (
                                        <Menu.Item
                                            key={9}
                                            onClick={cancel}
                                        >
                                            <Button
                                                type="link"
                                                style={{color: "inherit"}}
                                            >
                                                {t("INVOICES.CANCEL")}
                                            </Button>
                                        </Menu.Item>
                                    ) : null}

                                    {isAllowed(attributes, "faker") ? (
                                        <Menu.Item key={3} onClick={() => setSourcesListShown(true)}>
                                            <Button
                                                type="link"
                                                style={{color: "inherit"}}
                                            >
                                                Show Sources List
                                            </Button>
                                        </Menu.Item>
                                    ) : null}

                                    <Menu.Item key={4}>
                                        <Button
                                            type="link"
                                            style={{color: "inherit"}}
                                            onClick={() => sendDocumentToClient()}
                                        >
                                            {t("DOCUMENTS.SEND_TO_CLIENT")}
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type={"primary"}>{t("ACTION")}</Button>
                        </Dropdown>
                    </Row>

                    {document.lastSyncError ? (
                        <Alert
                            showIcon
                            type="error"
                            message={"Last sync ended with an error"}
                            description={document.lastSyncError}
                        />
                    ) : null}

                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label={t("CLIENT.NUMBER")}>
                                    <Link
                                        className="link"
                                        to={"/clients/" + document.agent.number}
                                    >
                                        {document.agent.number} (
                                        {document.agent.name})
                                    </Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPPING_WAREHOUSE")}>
                                    <Value value={document.warehouse.name} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DATE")}>
                                    <Date dateString={document.date!} showTime withSeconds />
                                </Descriptions.Item>
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => setTransactionEditModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            {transactionEditModalShown ? (
                <DocumentEditTransactionModal
                    id={document.id}
                    transaction={document}
                    onClose={() => setTransactionEditModalShown(false)}
                    onComplete={() => reload()}
                />
            ) : null}
            {sourcesListShown ? (
                <SourcesListModal
                    documentId={document.id}
                    onClose={() => setSourcesListShown(false)}
                />
            ) : null}
        </>
    );
});
